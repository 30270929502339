//钢管赋值壁厚内径
export function steelThicknessAndBore(basecodName, thickness) {
  let lineBase = {
    thickness: null,
    bore: null,
    standard: null
  }
  if (basecodName == 15) {
    lineBase.thickness = thickness || 3.25
    lineBase.standard = 21.25
    lineBase.bore = 21.25 - lineBase.thickness * 2
  } else if (basecodName == 20) {
    lineBase.thickness = thickness || 3.5
    lineBase.standard = 26.75
    lineBase.bore = 26.75 - lineBase.thickness * 2
  } else if (basecodName == 25) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 33.5
    lineBase.bore = 33.5 - lineBase.thickness * 2
  } else if (basecodName == 32) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 42.25
    lineBase.bore = 42.25 - lineBase.thickness * 2
  } else if (basecodName == 40) {
    lineBase.thickness = thickness || 4.25
    lineBase.standard = 48
    lineBase.bore = 48 - lineBase.thickness * 2
  } else if (basecodName == 50) {
    lineBase.thickness = thickness || 3.5
    lineBase.standard = 57
    lineBase.bore = 57 - lineBase.thickness * 2
  } else if (basecodName == 65) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 73
    lineBase.bore = 73 - lineBase.thickness * 2
  } else if (basecodName == 80) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 89
    lineBase.bore = 89 - lineBase.thickness * 2
  } else if (basecodName == 100) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 108
    lineBase.bore = 108 - lineBase.thickness * 2
  } else if (basecodName == 125) {
    lineBase.thickness = thickness || 4
    lineBase.standard = 133
    lineBase.bore = 133 - lineBase.thickness * 2
  } else if (basecodName == 150) {
    lineBase.thickness = thickness || 4.5
    lineBase.standard = 159
    lineBase.bore = 159 - lineBase.thickness * 2
  } else if (basecodName == 200) {
    lineBase.thickness = thickness || 6
    lineBase.standard = 219
    lineBase.bore = 219 - lineBase.thickness * 2
  } else if (basecodName == 250) {
    lineBase.thickness = thickness || 7
    lineBase.standard = 273
    lineBase.bore = 273 - lineBase.thickness * 2
  } else if (basecodName == 300) {
    lineBase.thickness = thickness || 7
    lineBase.standard = 325
    lineBase.bore = 325 - lineBase.thickness * 2
  } else if (basecodName == 350) {
    lineBase.thickness = thickness || 7
    lineBase.standard = 377
    lineBase.bore = 377 - lineBase.thickness * 2
  } else if (basecodName == 400) {
    lineBase.thickness = thickness || 8
    lineBase.standard = 426
    lineBase.bore = 426 - lineBase.thickness * 2
  } else if (basecodName == 450) {
    lineBase.thickness = thickness || 8
    lineBase.standard = 478
    lineBase.bore = 478 - lineBase.thickness * 2
  } else if (basecodName == 500) {
    lineBase.thickness = thickness || 8
    lineBase.standard = 529
    lineBase.bore = 529 - lineBase.thickness * 2
  } else if (basecodName == 600) {
    lineBase.thickness = thickness || 9
    lineBase.standard = 630
    lineBase.bore = 630 - lineBase.thickness * 2
  } else if (basecodName == 700) {
    lineBase.thickness = thickness || 9
    lineBase.standard = 720
    lineBase.bore = 720 - lineBase.thickness * 2
  } else if (basecodName == 800) {
    lineBase.thickness = thickness || 10
    lineBase.standard = 820
    lineBase.bore = 820 - lineBase.thickness * 2
  } else if (basecodName == 900) {
    lineBase.thickness = thickness || 10
    lineBase.standard = 920
    lineBase.bore = 920 - lineBase.thickness * 2
  } else if (basecodName == 1000) {
    lineBase.thickness = thickness || 11
    lineBase.standard = 1020
    lineBase.bore = 1020 - lineBase.thickness * 2
  }
  return lineBase
}

//pe11管赋值壁厚内径
export function pe11ThicknessAndBore(basecodName, thickness) {
  let lineBase = {
    thickness: null,
    bore: null,
    standard: null
  }
  if (basecodName == 20) {
    lineBase.thickness = thickness || 3
    lineBase.standard = 20
    lineBase.bore = 20 - lineBase.thickness * 2
  } else if (basecodName == 25) {
    lineBase.thickness = thickness || 3
    lineBase.standard = 25
    lineBase.bore = 25 - lineBase.thickness * 2
  } else if (basecodName == 32) {
    lineBase.thickness = thickness || 3
    lineBase.standard = 32
    lineBase.bore = 32 - lineBase.thickness * 2
  } else if (basecodName == 40) {
    lineBase.thickness = thickness || 3.7
    lineBase.standard = 40
    lineBase.bore = 40 - lineBase.thickness * 2
  } else if (basecodName == 50) {
    lineBase.thickness = thickness || 4.6
    lineBase.standard = 50
    lineBase.bore = 50 - lineBase.thickness * 2
  } else if (basecodName == 63) {
    lineBase.thickness = thickness || 5.8
    lineBase.standard = 63
    lineBase.bore = 63 - lineBase.thickness * 2
  } else if (basecodName == 75) {
    lineBase.thickness = thickness || 6.8
    lineBase.standard = 75
    lineBase.bore = 75 - lineBase.thickness * 2
  } else if (basecodName == 90) {
    lineBase.thickness = thickness || 8.2
    lineBase.standard = 90
    lineBase.bore = 90 - lineBase.thickness * 2
  } else if (basecodName == 110) {
    lineBase.thickness = thickness || 10
    lineBase.standard = 110
    lineBase.bore = 110 - lineBase.thickness * 2
  } else if (basecodName == 125) {
    lineBase.thickness = thickness || 11.4
    lineBase.standard = 125
    lineBase.bore = 125 - lineBase.thickness * 2
  } else if (basecodName == 140) {
    lineBase.thickness = thickness || 12.7
    lineBase.standard = 140
    lineBase.bore = 140 - lineBase.thickness * 2
  } else if (basecodName == 160) {
    lineBase.thickness = thickness || 14.6
    lineBase.standard = 160
    lineBase.bore = 160 - lineBase.thickness * 2
  } else if (basecodName == 180) {
    lineBase.thickness = thickness || 16.4
    lineBase.standard = 180
    lineBase.bore = 180 - lineBase.thickness * 2
  } else if (basecodName == 200) {
    lineBase.thickness = thickness || 18.2
    lineBase.standard = 200
    lineBase.bore = 200 - lineBase.thickness * 2
  } else if (basecodName == 225) {
    lineBase.thickness = thickness || 20.5
    lineBase.standard = 225
    lineBase.bore = 225 - lineBase.thickness * 2
  } else if (basecodName == 250) {
    lineBase.thickness = thickness || 22.7
    lineBase.standard = 250
    lineBase.bore = 250 - lineBase.thickness * 2
  } else if (basecodName == 315) {
    lineBase.thickness = thickness || 28.7
    lineBase.standard = 315
    lineBase.bore = 315 - lineBase.thickness * 2
  } else if (basecodName == 355) {
    lineBase.thickness = thickness || 32.3
    lineBase.standard = 355
    lineBase.bore = 355 - lineBase.thickness * 2
  } else if (basecodName == 400) {
    lineBase.thickness = thickness || 36.4
    lineBase.standard = 400
    lineBase.bore = 400 - lineBase.thickness * 2
  } else if (basecodName == 450) {
    lineBase.thickness = thickness || 40.9
    lineBase.standard = 450
    lineBase.bore = 450 - lineBase.thickness * 2
  } else if (basecodName == 500) {
    lineBase.thickness = thickness || 45.5
    lineBase.standard = 500
    lineBase.bore = 500 - lineBase.thickness * 2
  } else if (basecodName == 560) {
    lineBase.thickness = thickness || 51
    lineBase.standard = 560
    lineBase.bore = 560 - lineBase.thickness * 2
  } else if (basecodName == 630) {
    lineBase.thickness = thickness || 57.3
    lineBase.standard = 630
    lineBase.bore = 630 - lineBase.thickness * 2
  }
  return lineBase
}

//pe17管赋值壁厚内径
export function pe17ThicknessAndBore(basecodName, thickness) {
  let lineBase = {
    thickness: null,
    bore: null
  }
  if (basecodName == 20) {
    lineBase.thickness = thickness || 2.3
    lineBase.bore = 20 - lineBase.thickness * 2
  } else if (basecodName == 25) {
    lineBase.thickness = thickness || 2.3
    lineBase.bore = 25 - lineBase.thickness * 2
  } else if (basecodName == 32) {
    lineBase.thickness = thickness || 2.3
    lineBase.bore = 32 - lineBase.thickness * 2
  } else if (basecodName == 40) {
    lineBase.thickness = thickness || 2.3
    lineBase.bore = 40 - lineBase.thickness * 2
  } else if (basecodName == 50) {
    lineBase.thickness = thickness || 2.9
    lineBase.bore = 50 - lineBase.thickness * 2
  } else if (basecodName == 63) {
    lineBase.thickness = thickness || 3.6
    lineBase.bore = 63 - lineBase.thickness * 2
  } else if (basecodName == 75) {
    lineBase.thickness = thickness || 4.3
    lineBase.bore = 75 - lineBase.thickness * 2
  } else if (basecodName == 90) {
    lineBase.thickness = thickness || 5.2
    lineBase.bore = 90 - lineBase.thickness * 2
  } else if (basecodName == 110) {
    lineBase.thickness = thickness || 6.3
    lineBase.bore = 110 - lineBase.thickness * 2
  } else if (basecodName == 125) {
    lineBase.thickness = thickness || 7.1
    lineBase.bore = 125 - lineBase.thickness * 2
  } else if (basecodName == 140) {
    lineBase.thickness = thickness || 8
    lineBase.bore = 140 - lineBase.thickness * 2
  } else if (basecodName == 160) {
    lineBase.thickness = thickness || 9.1
    lineBase.bore = 160 - lineBase.thickness * 2
  } else if (basecodName == 180) {
    lineBase.thickness = thickness || 10.3
    lineBase.bore = 180 - lineBase.thickness * 2
  } else if (basecodName == 200) {
    lineBase.thickness = thickness || 11.4
    lineBase.bore = 200 - lineBase.thickness * 2
  } else if (basecodName == 225) {
    lineBase.thickness = thickness || 12.8
    lineBase.bore = 225 - lineBase.thickness * 2
  } else if (basecodName == 250) {
    lineBase.thickness = thickness || 14.2
    lineBase.bore = 250 - lineBase.thickness * 2
  } else if (basecodName == 315) {
    lineBase.thickness = thickness || 17.9
    lineBase.bore = 315 - lineBase.thickness * 2
  } else if (basecodName == 355) {
    lineBase.thickness = thickness || 20.2
    lineBase.bore = 355 - lineBase.thickness * 2
  } else if (basecodName == 400) {
    lineBase.thickness = thickness || 22.8
    lineBase.bore = 400 - lineBase.thickness * 2
  } else if (basecodName == 450) {
    lineBase.thickness = thickness || 25.6
    lineBase.bore = 450 - lineBase.thickness * 2
  } else if (basecodName == 500) {
    lineBase.thickness = thickness || 28.5
    lineBase.bore = 500 - lineBase.thickness * 2
  } else if (basecodName == 560) {
    lineBase.thickness = thickness || 31.9
    lineBase.bore = 560 - lineBase.thickness * 2
  } else if (basecodName == 630) {
    lineBase.thickness = thickness || 35.8
    lineBase.bore = 630 - lineBase.thickness * 2
  }
  return lineBase
}

export default {
  steelThicknessAndBore,
  pe11ThicknessAndBore,
  pe17ThicknessAndBore
}
